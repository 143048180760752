import './wall-items.scss';
import * as template from "./wall-items.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { InvipoItem } from "../../../clients/invipo-client/types";
import { MuklitComponent } from "muklit/components/muklit-component/muklit-component";
import { WallItemsOptions } from "./types";

const RELOAD_TIMEOUT = 30;

export class WallItems extends MuklitComponent<InvipoContext, WallItemsOptions> {

    // Properties
    public items: InvipoItem[] = null;
    public timer: any;

    // Event handling methods
    public onSelect(item: InvipoItem): void {};

    constructor(context: InvipoContext, options?: WallItemsOptions) {
        super(context, template, options);
    }

    public select(i: number): void {
        // OnSelect handler
        this.onSelect(this.items[i]);
    }

    public async load(): Promise<void> {
        // Clear timer
        clearTimeout(this.timer);

        // Filter items
        this.items = this.context.data.getItems({
            class: this.options.itemClass,
            model: this.options.itemModel,
            tag: this.options.itemTag,
            sort: "name:asc"
        });

        // Reset after one minute
        this.timer = setTimeout(() => {
            this.load()
        }, RELOAD_TIMEOUT * 1000);

        // Update
        this.update();
    }
}
