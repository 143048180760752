import {Helpers} from "./helpers";
import { Log } from "./log";

// Requires
let Handlebars = require("handlebars/dist/handlebars");

export class Templates {

    public static registerStaticHelpers(): void {
        // Out
        Handlebars.registerHelper("out", function(a: any) {
            Log.i(a)
            return a;
        });

        // Type
        Handlebars.registerHelper("is", function(a: any, t: string, options: any) {
            if (typeof a == t) {
                return options.fn(this);
            }
            return options.inverse(this);
        });

        // Has
        Handlebars.registerHelper("has", function(o: any, p: any, options: any) {
            if (o[p]) {
                return options.fn(this);
            }
            return options.inverse(this);
        });

        // Inclides
        Handlebars.registerHelper("includes", function(o: any[], p: any, options: any) {
            if (o?.includes(p)) {
                return options.fn(this);
            }
            return options.inverse(this);
        });

        // Non-empty object
        Handlebars.registerHelper("filled", function(o: any, options: any) {
            if (o && Object.keys(o).length > 0) {
                return options.fn(this);
            }
            return options.inverse(this);
        });

        // Length of array or object
        Handlebars.registerHelper("len", function(a: any, options: any) {
            return a?.length || Object.keys(a).length;
        });

        // Equals
        Handlebars.registerHelper("eq", function(x: any, y: any, options: any) {
            if (x == y) {
                return options.fn(this);
            }
            return options.inverse(this);
        });

        // Not equal
        Handlebars.registerHelper("ne", function(x: any, y: any, options: any) {
            if (x != y) {
                return options.fn(this);
            }
            return options.inverse(this);
        });

        // Less than
        Handlebars.registerHelper("lt", function(x: any, y: any, options: any) {
            if (x < y) {
                return options.fn(this);
            }
            return options.inverse(this);
        });

        // Greater than
        Handlebars.registerHelper("gt", function(x: any, y: any, options: any) {
            if (x > y) {
                return options.fn(this);
            }
            return options.inverse(this);
        });

        // Between
        Handlebars.registerHelper("between", function(a: any, x: any, y: any, options: any) {
            if (a > x && a < y) {
                return options.fn(this);
            }
            return options.inverse(this);
        });

        // In
        Handlebars.registerHelper("in", function(x: string, y: any, options: any) {
            if (typeof y == "string" && y.split(",").includes(x)) {
                return options.fn(this);
            }
            if (typeof y == "object" && y.length && y.includes(x)) {
                return options.fn(this);
            }
            return options.inverse(this);
        });

        // Addition
        Handlebars.registerHelper("add", function(x: number, y: number, options: any) {
            return x + y;
        });

        // Subtraction
        Handlebars.registerHelper("sub", function(x: number, y: number, options: any) {
            return x - y;
        });

        // Multiplication
        Handlebars.registerHelper("mul", function(x: number, y: number, options: any) {
            return x * y;
        });

        // Division
        Handlebars.registerHelper("div", function(x: number, y: number, options: any) {
            return x / y;
        });

        // Modulo
        Handlebars.registerHelper("mod", function(x: number, y: number, options: any) {
            return x % y;
        });

        // Floor
        Handlebars.registerHelper("floor", function(n: number, options: any) {
            if (n != null && typeof n == "number") {
                return Math.floor(n);
            }
            else {
                return null;
            }
        });

        // Times
        Handlebars.registerHelper("times", function(n: number, options: any) {
            let result = "";
            for(let i = 0; i < n; ++i) {
                result += options.fn(i + 1);
            }
            return result;
        });

        // First
        Handlebars.registerHelper("first", function(n: number, c: number, options: any) {
            let result = "";
            for(let i = 0; i < c; ++i) {
                result += options.fn(i + 1);
            }
            return result;
        });

        // last
        Handlebars.registerHelper("last", function(n: number, c: number, options: any) {
            let result = "";
            for(let i = n - c; i < n; ++i) {
                result += options.fn(i + 1);
            }
            return result;
        });

        // host
        Handlebars.registerHelper("host", (s: string) => {
            return new URL(s).hostname;
        });

        // kebab-case
        Handlebars.registerHelper("kebabcase", (s: string) => {
            return Helpers.toKebabCase(s);
        });

        // asDate
        Handlebars.registerHelper("asDate", function(t: string) {
            return Helpers.toDateString(t);
        });

        // asShortDate
        Handlebars.registerHelper("asShortDate", function(t: string) {
            return Helpers.toShortDateString(t);
        });

        // asShortIsoDate
        Handlebars.registerHelper("asShortIsoDate", function(t: string) {
            return Helpers.toShortIsoDateString(t);
        });

        // asTime
        Handlebars.registerHelper("asTime", function(t: string) {
            return Helpers.toTimeString(t);
        });

        // asShortTime
        Handlebars.registerHelper("asShortTime", function(t: string) {
            return Helpers.toShortTimeString(t);
        });

        // asDateTime
        Handlebars.registerHelper("asDateTime", function(t: string) {
            return Helpers.toDateTimeString(t);
        });

        // asDateTime
        Handlebars.registerHelper("asShortDateTime", function(t: string) {
            return Helpers.toShortDateTimeString(t);
        });

        // asLocalIsoDate
        Handlebars.registerHelper("asLocalIsoDate", function(t: string) {
            return Helpers.toLocalIsoDate(t);
        });

        // asLocalIsoTime
        Handlebars.registerHelper("asLocalIsoTime", function(t: string) {
            return Helpers.toLocalIsoTime(t);
        });

        // asHour
        Handlebars.registerHelper("asHour", function(t: string) {
            return Helpers.toHour(t);
        });

        // asMinute
        Handlebars.registerHelper("asMinute", function(t: string) {
            return Helpers.toMinute(t);
        });

        // asMonth
        Handlebars.registerHelper("asMonth", function(t: string) {
            return Helpers.toMonthString(t);
        });

        // asYear
        Handlebars.registerHelper("asYear", function(t: string) {
            return Helpers.toYear(t);
        });

        // asShortMonth
        Handlebars.registerHelper("asShortMonth", function(t: string) {
            return Helpers.toShortMonthString(t);
        });

        // asWeekDay
        Handlebars.registerHelper("asWeekDay", function(t: string) {
            return Helpers.toWeekDayString(t);
        });

        // asShortWeekDay
        Handlebars.registerHelper("asShortWeekDay", function(t: string) {
            return Helpers.toShortWeekDayString(t);
        });

        // asNumber
        Handlebars.registerHelper("asNumber", function(t: string, d: number, options: any) {
            return Helpers.toNumber(t, options ? d : 2);
        });

        // asMb
        Handlebars.registerHelper("asMb", function(t: string) {
            return Helpers.toMb(t);
        });

        // asVph
        Handlebars.registerHelper("asVph", function(n: number, interval: number) {
            return Helpers.toVph(n, interval);
        });

        // asKwh
        Handlebars.registerHelper("asKwh", function(n: number, interval: number) {
            return Helpers.toKwh(n, interval);
        });

        // asDuration
        Handlebars.registerHelper("asDuration", function(t: number) {
            return Helpers.toDuration(t);
        });

        // asShortDuration
        Handlebars.registerHelper("asShortDuration", function(t: number) {
            return Helpers.toShortDuration(t);
        });

        // asAge
        Handlebars.registerHelper("asAge", function(t: string) {
            return Helpers.toAge(t);
        });

        // asLogLine
        Handlebars.registerHelper("asLogLine", function(t: string) {
            return Helpers.toLogLine(t);
        });

        // asJson
        Handlebars.registerHelper("asJson", function(v: any) {
            if (v == null) {
                return "";
            }

            let json = JSON.stringify(v, null, 2);;

            // Colorize!
            return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
                let type = "number";

                if (/^"/.test(match)) {
                    if (/:$/.test(match)) {
                        type = "key";
                    } else {
                        type = "string";
                    }
                }
                else if (/true|false/.test(match)) {
                    type = "boolean";
                }
                else if (/null/.test(match)) {
                    type = 'null';
                }

                return `<span class="${type}">${match}</span>`;
            });
        });

        // asDuration
        Handlebars.registerHelper("agent", function(name: string, options: any) {
            if (navigator.userAgent.includes(name)) {
                return options.fn(this);
            }
            return options.inverse(this);
        });

        Log.i(`Templates: Static functions registered`);
    }

    public static registerPartial(name: string, partial: any): void {
        Handlebars.registerPartial(name, partial);
    }

    public static renderPartial(name: string, data: any): string {
        let template = Handlebars.compile(`{{>${name}}}`);
        return template(data);
    }
}
