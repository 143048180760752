// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.invipo-basic-map {
  position: relative;
  width: 100%;
  height: 100%;
}
div.invipo-basic-map div.mapboxgl-canvas-container canvas {
  outline: none;
  animation: keyframes-basic-map-fade-in 1200ms;
}
div.invipo-basic-map div.mapboxgl-control-container {
  display: none;
}
@keyframes keyframes-basic-map-fade-in {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}`, "",{"version":3,"sources":["webpack://./src/muklit/components/basic-map/basic-map.scss"],"names":[],"mappings":"AAGA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;AAFF;AAKI;EACE,aAAA;EACA,6CAAA;AAHN;AAOE;EACE,aAAA;AALJ;AAQE;EACE;IACE,UAAA;EANJ;EAQE;IACE,UAAA;EANJ;EAQE;IACE,UAAA;EANJ;AACF","sourcesContent":["@import \"../../styles/mixins\";\r\n@import \"../../styles/colors\";\r\n\r\ndiv.invipo-basic-map {\r\n  position: relative;\r\n  width: 100%;\r\n  height: 100%;\r\n\r\n  div.mapboxgl-canvas-container {\r\n    canvas {\r\n      outline: none;\r\n      animation: keyframes-basic-map-fade-in 1200ms;\r\n    }\r\n  }\r\n\r\n  div.mapboxgl-control-container {\r\n    display: none;\r\n  }\r\n\r\n  @keyframes keyframes-basic-map-fade-in {\r\n    0% {\r\n      opacity: 0;\r\n    }\r\n    70% {\r\n      opacity: 0;\r\n    }\r\n    100% {\r\n      opacity: 1;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
