import './travel-time-subdomain.scss';
import * as template from "./travel-time-subdomain.hbs";
import { InvipoContext } from "../../../context/invipo-context";
import { TravelTimeSubdomainOptions } from "./types";
import { CitySubdomain } from "../../city/city-subdomain/city-subdomain";
import { METRICS } from "../../city/city-subdomain/types";
import { ItemList } from "../../city/item-list/item-list";
import { ItemLabelLayer } from "../../../layers/infrastructure/item-label-layer";
import { ItemCircleLayer } from "../../../layers/city/item-circle-layer";

export const TRAVEL_TIME_CLASS = "BluetoothDetector";

export class TravelTimeSubdomain extends CitySubdomain<TravelTimeSubdomainOptions> {

    constructor(context: InvipoContext, options?: TravelTimeSubdomainOptions) {
        super(context, template, {
            metrics: [
                {
                    name: "Delay",
                    itemClass: TRAVEL_TIME_CLASS,
                    layers: [
                    ]
                }
            ],
            ...options
        });
    }

    public selectDevices(): void {
        // Crate item list
        let list = new ItemList(this.context, {
            style: "Light",
            title: "components.CitySubdomain.devices",
            subtitle: "components.TravelTimeSubdomain.devices",
            layers: [
                new ItemCircleLayer(this.context, this.items),
                new ItemLabelLayer(this.context, this.items)
            ]
        });

        // Items already loaded, will use them
        list.items = this.items;

        // Call handler that will open list
        this.onListCreate(list, this.metrics);
    }

    public async extraLoad(): Promise<void> {
        // Occupncy metrics?
        if (this.metrics.name == "Delay") {
            // Create volume legend
            this.legend = {
                description: "components.TravelTimeSubdomain.delayDescription",
                range: {
                    range: METRICS.traffic.travel.delay.range,
                    colors: METRICS.traffic.travel.delay.colors,
                    unit: METRICS.traffic.travel.delay.unit,
                    value: 0,
                    count: 0,
                },
                symbols: []
            }
        }
    }
}
