import { WorkflowViolationBrowserOptions } from "./types";
import { MenuItem } from "muklit/components/overflow-menu/types";
import { FilterTable } from "muklit/components/filter-table/filter-table";
import { FilterItem } from "muklit/components/filter/types";
import { Select } from "muklit/components/select/select";
import { AutocompleteSelect } from "../../common/autocomplete-select/autocomplete-select";
import { TextInput } from "muklit/components/text-input/text-input";
import { ViolationBrowser } from "../violation-browser/violation-browser";
import { Search } from "muklit/components/search/search";
import { RangeInput } from "muklit/components/range-input/range-input";
import { Helpers } from "../../../../hiyo/helpers";

export class WorkflowViolationBrowser extends ViolationBrowser<WorkflowViolationBrowserOptions> {

    public createTable(): void {
        // Create menu items from columns
        let columns: MenuItem[] = [];

        for (let c of this.context.config.violations.columns) {
            columns.push({
                name: c,
                label: c
            })
        }

        // Create component
        this.table = new FilterTable(this.context, {
            style: "Light",
            url: `${this.context.options.host}/api/data/datasets/violations-data`,
            http: this.context.invipo.http,
            filter: {
                title: "components.WorkflowViolationBrowser.title",
                items: [
                    {
                        name: "Export",
                        label: "labels.export"
                    },
                    {
                        name: "Reload",
                        label: "labels.reload"
                    }
                ]
            },
            pagination: {
                page: 1,
                pageSize: 25
            },
            form: {
                fieldsets: [
                    {
                        name: "general",
                        fields: [
                            new Search(this.context, {
                                style: "Light",
                                name: "search",
                                label: "forms.fields.search",
                                placeholderText: "forms.placeholders.idSearch"
                            }),
                            new RangeInput(this.context, {
                                style: "Light",
                                name: "interval",
                                type: "Range",
                                time: true,
                                label: "forms.fields.date",
                                placeholderText: "forms.placeholders.anytime"
                            }),
                            new Select(this.context, {
                                style: "Light",
                                name: "workflow.column",
                                label: "forms.fields.status",
                                placeholderText: "forms.placeholders.all",
                                multiselect: true,
                                items: columns
                            }),
                            new AutocompleteSelect(this.context, {
                                style: "Light",
                                name: "workflow.assignee.id",
                                label: "forms.fields.assignee",
                                collection: "Users",
                                type: "Name",
                                placeholderText: "forms.placeholders.nobody",
                                items: []
                            }),
                            new TextInput(this.context, {
                                style: "Light",
                                name: "workflow.reference",
                                label: "forms.fields.reference"
                            }),
                            new Select(this.context, {
                                style: "Light",
                                name: "workflow.completed",
                                label: "forms.fields.completed",
                                value: "false",
                                placeholderText: "forms.placeholders.all",
                                items: [
                                    {
                                        name: "false",
                                        label: "components.WorkflowViolationBrowser.onlyIncompleted"
                                    },
                                    {
                                        name: "true",
                                        label: "components.WorkflowViolationBrowser.onlyCompleted"
                                    }
                                ]
                            })
                        ]
                    }
                ]
            },
            table: {
                type: "SingleSelect",
                size: "Short",
                height: "100%",
                groups: {
                    property: "timestamp",
                    formatter: (value: any, data: any) => {
                        let today = new Date();
                        let yesterday = new Date(new Date().setHours(-24));

                        // Today?
                        if (new Date(value).toDateString() == today.toDateString()) {
                            return "common.today";
                        }
                        // Yesterday?
                        else if (new Date(value).toDateString() == yesterday.toDateString()) {
                            return "common.yesterday";
                        }
                        // Just some date in past
                        else {
                            return "common.history";
                        }
                    }
                },
                rows: {
                    id: "id",
                    decorator: (data: any): string => {
                        let result = null;

                        // Archived?
                        if (data.workflow?.archived) {
                            result = "disabled"
                        }

                        // Completed?
                        if (data.workflow?.completed) {
                            result = "disabled"
                        }

                        // Overdue?
                        if (data.workflow?.due && new Date().getTime() > new Date(data.workflow.due).getTime()) {
                            result = "deleted"
                        }

                        return result;
                    }
                },
                columns: [
                    {
                        name: "timestamp",
                        type: "DateTime",
                        property: "timestamp",
                        label: "tables.columns.timestamp",
                        width: 160,
                        sortable: true,
                        selected: true,
                        descendent: true,
                    },
                    {
                        name: "type",
                        type: "String",
                        property: (data: any): any => {
                            return this.context.locale.getMessage(`enums.ViolationType.${data.type}`);
                        },
                        label: "tables.columns.type",
                        width: 160,
                        sortable: true,
                        ellipsis: true
                    },
                    {
                        name: "titile",
                        type: "String",
                        property: "workflow.title",
                        label: "tables.columns.title",
                        width: 240,
                        sortable: true,
                        ellipsis: true
                    },
                    {
                        name: "column",
                        type: "String",
                        property: "workflow.column",
                        label: "tables.columns.status",
                        width: 260,
                        sortable: true,
                        ellipsis: true
                    },
                    {
                        name: "reference",
                        type: "String",
                        property: "workflow.reference",
                        label: "tables.columns.reference",
                        width: 160,
                        sortable: true,
                        ellipsis: true
                    },
                    {
                        name: "assignee",
                        type: "String",
                        property: "workflow.assignee.name",
                        label: "tables.columns.assignee",
                        minWidth: 260,
                        sortable: true,
                        ellipsis: true
                    }
                ]
            }
        });

        // Handle menu selection
        this.table.onItemSelect = (item: FilterItem) => {
            if (item.name == "Export") {
                // Open export dialog
                this.openExport();
            }
        }

        // Open detail
        this.table.onDataSelect = async (data: any) => {
            await this.openDetail(data);
        }

        // Register component
        this.registerComponent(this.table, "table");
    }
}
