import { DrawerMenu } from "../components/common/drawer-menu/drawer-menu";
import { DrawerItem } from "../components/common/drawer-menu/types";
import { MenuView } from "./menu-view";

export class Settings extends MenuView {

    public createMenu(): void {
        // Create component
        this.menu = new DrawerMenu(this.context, {
            style: "Light",
            groups: [
                {
                    name: "Platform",
                    items: [
                        {
                            name: "NotificationManager",
                            label: "components.NotificationManager.title"
                        },
                        {
                            name: "AreaManager",
                            label: "components.AreaManager.title",
                        },
                        {
                            name: "UserManager",
                            label: "components.UserManager.title"
                        },
                        {
                            name: "TemplateManager",
                            label: "components.TemplateManager.title"
                        }
                    ]
                }
            ]
        });

        this.menu.onSelect = (item: DrawerItem) => {
            // Already selected?
            if (this.content?.name == item.name) {
                return;
            }

            // Change content component
            this.setContent(item.name);
        }
    }

}
